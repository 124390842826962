/* You can add global styles to this file, and also import other style files */
@import "src/app/styles/mixins";

.app-container {
  min-height: 50rem;
}

.page-container {
  padding: 2rem 4rem 4rem 4rem;

  @include screen('desktop') {
    padding: 1rem;
  }

  @include screen('tablet-landscape') {
    padding: .75rem;
  }
  @include screen('phone') {
    padding: .5rem;
  }
}

.card-container {
  width: 100%;
  margin-bottom: 1rem;
}
